import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public url: string
  public identity
  public token
  public role

  constructor(
    public httpClient: HttpClient
  ) {
    this.url = environment.url;
  }

  signup(info): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    return this.httpClient.post(this.url + 'login', info, { headers: headers })
  }

  checkPayload(token): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token)
		return this.httpClient.get(this.url + 'checkPayload', { headers: headers })
  }

  show(token): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token)
		return this.httpClient.get(this.url + 'user', { headers: headers })
  }

  index(token): Observable<any> {
		let headers = new HttpHeaders().set('Authorization', token)
		return this.httpClient.get(this.url + 'users', { headers: headers })
  }

  store(token, info): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
    return this.httpClient.post(this.url + 'users', info, { headers: headers })
  }

  update(token, info, user_id): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
    return this.httpClient.put(this.url + 'users/' + user_id, info, { headers: headers })
  }
  
  getToken() {
		let token = localStorage.getItem('token')
		if (token != "undefined") {
			this.token = token
		} else {
			this.token = null
		}
		return this.token
  }
  
  getRole(){
    let role = localStorage.getItem('role')
		if (role != "undefined") {
			this.role = role
		} else {
			this.role = null
		}
		return this.role
  }


}
