export class User{
	constructor(
		public id: number, 
		public dev_id: number,
		public role: number,
		public code: string,
		public name_room: string,
		public user: string,
		public name: string,
		public lastname: string,
		public password: string,
		public re_password: string,
		public active: number
	){}
}