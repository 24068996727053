import { Component, OnInit } from '@angular/core';
import { Router, Params } from '@angular/router';
import { User } from '../models/user';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserService]
})
export class LoginComponent implements OnInit {
  public user: User;
  public status: string;
  public message_success: string;
  public errors: string;
  public token: any;

  constructor(
    private router: Router,
    private userService: UserService
  ) {
    this.token = this.userService.getToken();
    this.user = new User(1, 1, 1, '', '', '', '', '', '', '', 1)
  }

  ngOnInit() {
    this.token = this.userService.getToken()
    if (this.token != null) {
      this.router.navigate(['statistics']);
    }
  }

  singUp(form) {
    this.status = 'loading';
    this.message_success = 'Loading...';
    this.userService.signup(this.user).subscribe(
      response => {
        $('.login').show()

        this.status = 'success'
        this.message_success = response.info[0]['message']
        this.token = response.info[0]['data']

        localStorage.setItem('token', this.token)
        localStorage.setItem('name', response.info[0]['name'])
        localStorage.setItem('role', response.info[0]['role'])

        this.router.navigate(['statistics'])

        this.durationMessage('login')
      }, error => {
        $('.login').show()
        this.status = 'error'
        this.errors = error.error.errors
        this.durationMessage('login')
      }
    )
  }

  durationMessage(label) {
    window.setTimeout(function () {
      $('.' + label).fadeTo(500, 1).slideUp(500, function () {
        $('.' + label).hide()
      });
    }, 3000)
  }

}
