import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user/user.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  public token

  constructor(
    private router: Router,
    private userService: UserService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(next, state)
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check(next, state)
  }

  private check(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.token = this.userService.getToken()
    if (this.token === null) {
      this.router.navigate(['login']);
      return false;
    }

    return this.userService.checkPayload(this.token).pipe(
      map(
        response => {
          return true
        }), catchError(() => {
          alert('Su sesión ha expirado, favor de volverse a loguear!')
          localStorage.removeItem('name')
          localStorage.removeItem('token')
          this.router.navigate(['login'])
          return of(false);
        })
    )
  }

}
