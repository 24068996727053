import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  tooltip: string;
  role: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  providers: [UserService]
})

export class SidebarComponent implements OnInit {
  menuItems: any[];
  public role: string = null

  constructor(
    private userService: UserService,
  ) {
    this.role = this.userService.getRole()
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}

export const ROUTES: RouteInfo[] = [
  { path: '/statistics', title: 'Statistics', tooltip: 'General overviews', icon: 'pie_chart', class: '', role: '' },
  { path: '/create-contract', title: 'Create Contract', tooltip: 'Create a new contract', icon: 'create', class: '', role: '' },
  { path: '/send-fbgs', title: 'Send to FBGS', tooltip: 'Send contracts to FBGS', icon: 'send', class: '', role: '' },
  { path: '/contracts', title: 'Contracts', tooltip: 'Sent contracts in the current month', icon: 'content_paste', class: '', role: '' },
  { path: '/authorize_fund', title: 'Authorize Funds', tooltip: 'Fund pending authorization by exotic', icon: 'attach_money', class: '', role: 'ADMIN' },
  { path: '/contracts_authorized', title: 'Authorized Contracts', tooltip: 'Authorized funds by exotic', icon: 'file_copy', class: '', role: '' },
  { path: '/contracts_rejected', title: 'Rejected Funds', tooltip: 'Rejected funds by exotic', icon: 'cancel', class: '', role: '' },
  { path: '/contracts_current', title: 'Current Collection', tooltip: 'Current Collection', icon: 'schedule', class: '', role: '' },
  { path: '/contracts_overdue', title: 'Overdue Contracts', tooltip: 'Overdue Contracts', icon: 'report_problem', class: '', role: '' },
  { path: '/contracts_cancelrequest', title: 'Cancel Request Contracts', tooltip: 'Contracts with Cancel Request', icon: 'cancel', class: '', role: ''},
  { path: '/contracts_canceled', title: 'Canceled Contracts', tooltip: 'Canceled Contracts', icon: 'cancel', class: '', role: '' },
  { path: '/contracts_completed', title: 'Finished Contracts', tooltip: 'Finished Contracts', icon: 'done_all', class: '', role: '' },
  { path: '/summary', title: 'Summary', tooltip: 'Reports Online', icon: 'file_copy', class: '', role: '' },
  { path: '/reports', title: 'Reports', tooltip: 'Excel Reports', icon: 'file_copy', class: '', role: '' },
  { path: '/users', title: 'Users', tooltip: 'Users', icon: 'person', class: '', role: 'ADMIN' },
  { path: '/user-profile', title: 'User Profile', tooltip: 'User Profile information', icon: 'how_to_reg', class: '', role: '' },
  { path: '/logout/1', title: 'Logout', tooltip: 'Logout', icon: 'exit_to_app', class: '', role: '' },
  //{ path: '/notifications', title: 'Notifications',  icon:'notifications', class: '' },
];
