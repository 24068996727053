import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { ContractService } from '../../services/contract/contract.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-getpdf',
  templateUrl: './getpdf.component.html',
  styleUrls: ['./getpdf.component.scss']
})
export class GetpdfComponent implements OnInit {
  public token: any
  public file: any
  public status: string

  constructor(
    private route: ActivatedRoute,
    private contractService: ContractService,
    private userService: UserService
  ) {
    this.token = this.userService.getToken()
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id = +params['contract_id'];
      this.getFile(id);
    })
  }

  getFile(id) {
    this.contractService.getPdf(this.token, id).subscribe(
      response => {
        this.file = URL.createObjectURL(response);
        location.href = this.file;
      },
      error => {
        alert('There are problems at the momment to get the file, try letter')
        window.close()
      }
    );
  }
}
