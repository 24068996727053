import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  public url: string
  public token

  constructor(
    public httpClient: HttpClient
  ) {
    this.url = environment.url;
  }

  getContracts(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.get(this.url + 'contracts', { headers: headers })
  }

  getPdf(token, contract_id): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
		return this.httpClient.get(this.url+'getPdf/'+contract_id, {headers: headers, responseType: 'blob'});
  }

  getContractsByStatus(token, status, pre_fund): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    const prefund_path = pre_fund !== null ? '/' + pre_fund : ''
    return this.httpClient.get(this.url + 'contractsByStatus/' + status + prefund_path, { headers: headers })
  }

  getContractsWithCancelRequest(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.get(this.url + 'contractsWithCancelRequest/', { headers: headers })
  }

  statistics(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.get(this.url + 'statistics', { headers: headers })
  }

  getPrefundContract(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.get(this.url + 'prefund', { headers: headers })
  }

  updatePrefundContract(token, info): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.post(this.url + 'prefund', info, { headers: headers })
  }

  cancelRequest(token, info): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.post(this.url + 'contractCancelRequest', info, { headers: headers })
  }

  getTotalLoanAmount(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.get(this.url + 'totalLoanAmount', { headers: headers })
  }

  getExcelReport(token, reportType, startDate, endDate) {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.post(this.url + 'reports', {reportType, startDate, endDate}, { headers, responseType: 'blob' })
  }

  getExcelReportFull(token, startDate, endDate) {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.post(this.url + 'salesReport', {startDate, endDate}, { headers, responseType: 'blob' })
  }

  getExcelDeclinedReport(token) {
    let headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.get(this.url + 'declineds', { headers, responseType: 'blob' })
  }

  getAuthorizedContracts(token, startDate, endDate): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this.httpClient.post(this.url + 'authorizedContracts', {startDate, endDate}, { headers })
  }

  getContractsSummary(token, salesRoom): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this.httpClient.post(this.url + 'contractSummary', {salesRoom}, { headers })
  }

  getContractsWithOutPdf(token): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token);
    return this.httpClient.post(this.url + 'getContractsWithOutPdf', {}, { headers })
  }

  store(token, info): Observable<any> {
    let headers = new HttpHeaders().set('Authorization', token).set('Content-Type', 'application/json')
    return this.httpClient.post(this.url + 'create_contract', info, { headers: headers })
  }

  public uploadFile(token, file, contract_name): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', token)
    return this.httpClient.post(`${this.url}attachPdfByGusaCollection/`+contract_name, file, { headers: headers });
  }
}
